<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-select
            v-model="type"
            style="width: 200px"
            placeholder="请选择类型"
        >
          <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item"
              :value="index"
          />
        </el-select>
        <el-date-picker
            style="width: 400px;margin: 0 20px"
            v-model="date"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="title" align="center" label="发布人" />
        <el-table-column prop="userName" align="center" label="进出帐类型" />
        <el-table-column prop="createTime" align="center" label="金额" />
        <el-table-column prop="createTime" align="center" label="操作时间" />
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addCircleDaily,selectCircleDailyList,upCircleDaily,delCircleDaily} from "@/api/square";

export default {
  name: "Runningwaterrecord",
  components: {
    commonTable
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      date:'',
      type:'',
      typeList:['进账','出账'],
      tableData:[],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
    };
  },
  computed: {

  },
  mounted(){
    // this.queryPage();
  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectCircleDailyList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          list[i].ifShow = list[i].ifShow==0?true:false
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //搜索
    async filterNames() {
      this.tableData = this.tableData.filter(
          (item) => this.filterName == item.name
      );
      if (this.filterName.length < 1) {
        this.queryPage();
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>



